import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

console.log("process.env.REACT_API", process.env.REACT_APP_API)

axios.defaults.baseURL = process.env.REACT_APP_API

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        if (response && response.status === 403) {
           console.log(response.data)
           if (response.data && response.data.message === "Invalid or expired token.") {
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem("userData")
           }
           // Invalid or expired token.
        }

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  requestPasswordReset(payload) {
    return axios.post('/auth/request-password-reset', payload)
  }

  resetPassword(payload, token) {
    return axios.post('/auth/reset-password', payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  users(params) {
    return axios.get(this.jwtConfig.usersEndpoint, { params })
  }

  fetchUser(id) {
    return axios.get(`${this.jwtConfig.usersEndpoint}/${id}`)
  }

  updateUser(id, payload) {
    return axios.put(`${this.jwtConfig.usersEndpoint}/${id}`, payload)
  }

  fetchAdminDashboard() {
    return axios.get(this.jwtConfig.adminDashboardEndpoint)
  }

  fetchSchoolImages() {
    return axios.get("/schools/attachments")
  }

  deleteSchoolImages(attachment_ids) {
    return axios.delete(`/schools/attachments?attachment_ids=${attachment_ids}`)
  }

  uploadSchoolImages(formData) {
    return axios.post("/schools/attachments", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  logout() {
    return axios.post(this.jwtConfig.logoutEndpoint)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  confirmAccount(token) {
    return axios.post(this.jwtConfig.confirmAccountEndpoint, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  adminCreateSchool(...args) {
    return axios.post(this.jwtConfig.schoolsEndpoint, ...args)
  }

  schools(params) {
    return axios.get(this.jwtConfig.schoolsEndpoint, { params })
  }

  fetchSchool(id) {
    return axios.get(`${this.jwtConfig.schoolsEndpoint}/${id}`)
  }

  fetchSchoolBySlug(slug) {
    return axios.get(`${this.jwtConfig.schoolsEndpoint}/slugs/${slug}`)
  }

  updateSchool(id, payload) {
    return axios.put(`${this.jwtConfig.schoolsEndpoint}/${id}`, payload)
  }

  updateClientSchool(id, payload) {
    return axios.put(`/client/school/${id}`, payload)
  }

  updateClientSchoolFees(id, payload) {
    return axios.put(`/client/school/${id}/fees`, payload)
  }

  onboardSchool(payload) {
    return axios.post(`${this.jwtConfig.onboardEndpoint}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  getClientSchool() {
    return axios.get('/client/school')
  }

  updateSchoolLogo(id, formData) {
    return axios.put(`${this.jwtConfig.schoolsEndpoint}/${id}/logo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  updateClientSchoolLogo(id, formData) {
    return axios.put(`/client/school/${id}/logo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  searchSchool(params) {
    return axios.get(`${this.jwtConfig.schoolsEndpoint}/search`, { params })
  }

  makeGeneralInquiry(payload) {
    return axios.post(`${this.jwtConfig.inquiryEndpoint}/general`, payload)
  }

  makeSchoolInquiry(payload) {
    return axios.post(`${this.jwtConfig.inquiryEndpoint}/school`, payload)
  }

  fetchGeneralInquiry(params) {
    return axios.get(`${this.jwtConfig.inquiryEndpoint}/general`, {
      params
    })
  }

  fetchSchoolInquiry(params) {
    return axios.get(`/school-inquiry`, {
      params
    })
  }

  downloadSchoolInquiry(params) {
    return axios.get(`/school-inquiry/export`, {
      params,
      responseType: "blob"
    })
  }

  markGeneralInquiryAsResponded(id) {
    return axios.put(`${this.jwtConfig.inquiryEndpoint}/general/${id}/responded`)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }
}
