// ** Auth Endpoints
export default {
  loginEndpoint: '/auth/login',
  registerEndpoint: '/auth/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/auth/logout',

  // Other endpoints
  usersEndpoint: '/users',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  // ADMIN
  schoolsEndpoint: "/schools",
  adminDashboardEndpoint: "/admin/dashboard",

  confirmAccountEndpoint: "/auth/confirm-email",
  onboardEndpoint: "/onboard",


  // PUBLIC
  inquiryEndpoint: "/inquiry"
}
