// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { get } from 'lodash'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    accessToken: ""
  },
  reducers: {
    updateUser: (state, action) => {
      const { user } = action.payload
      state.userData = user
      localStorage.setItem('userData', JSON.stringify(user))
    },
    handleLogin: (state, action) => {
      const { user, token } = action.payload
      state.userData = user
      state[config.storageTokenKeyName] = token
      console.log("USER DATA", user)
      localStorage.setItem('userData', JSON.stringify(user))
      localStorage.setItem(config.storageTokenKeyName, token)
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = ""
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
    }
  }
})

export const { handleLogin, handleLogout, updateUser } = authSlice.actions

export const userSelector = (state) => get(state, "auth.userData", {})

export default authSlice.reducer
