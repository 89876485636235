import mock from '../mock'

const data = {
  pricing: {
    basicPlan: {
      title: 'Basic',
      img: require('@src/assets/images/illustration/Pot1.svg').default,
      subtitle: 'For everyone',
      monthlyPrice: 0,
      yearlyPlan: {
        perMonth: 0,
        totalAnnual: 0
      },
      planBenefits: ['Free profile listing'],
      popular: false
    },
    standardPlan: {
      title: 'Advanced',
      img: require('@src/assets/images/illustration/Pot2.svg').default,
      subtitle: 'For small to medium businesses',
      monthlyPrice: 50,
      yearlyPlan: {
        perMonth: 40,
        totalAnnual: 480
      },
      planBenefits: [
        'Profile Listing',
        'Curated Youtube videos',
        'Limited earned media on academy lens platform'
      ],
      popular: true
    },
    enterprisePlan: {
      title: 'Premium',
      img: require('@src/assets/images/illustration/Pot3.svg').default,
      subtitle: 'Solution for big organizations',
      monthlyPrice: 200,
      yearlyPlan: {
        perMonth: 80,
        totalAnnual: 960
      },
      planBenefits: [
        'Profile Listing',
        'Curated Youtube videos',
        'Ability to create student-led content',
        'Manage school social media accounts',
        'Featured in searches',
        'Earned media',
        "Part of top recommendations"
      ],
      popular: false
    },
    customPlan: {
      title: 'Custom',
      img: require('@src/assets/images/illustration/Pot3.svg').default,
      subtitle: 'Solution for schools with special services that are currently not listed.',
      monthlyPrice: 200,
      yearlyPlan: {
        perMonth: 80,
        totalAnnual: 960
      },
      planBenefits: [
        'All benefits available from basic to premium',
        'Targetted school social media campgaings',
        'A customized service tailored to your specific needs'
      ],
      popular: false
    },
    qandA: [
      {
        question: 'Does my subscription automatically renew?',
        ans:
          'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.'
      },
      {
        question: 'Can I store the item on an intranet so everyone has access?',
        ans:
          'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.'
      },
      {
        question: 'Am I allowed to modify the item that I purchased?',
        ans:
          'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.'
      }
    ]
  }
}

mock.onGet('/pricing/data').reply(() => [200, data.pricing])
